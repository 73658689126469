import { render, staticRenderFns } from "./CheckboxButton.vue?vue&type=template&id=2976b26d&scoped=true&"
import script from "./CheckboxButton.vue?vue&type=script&lang=ts&"
export * from "./CheckboxButton.vue?vue&type=script&lang=ts&"
import style0 from "./CheckboxButton.vue?vue&type=style&index=0&id=2976b26d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2976b26d",
  null
  
)

export default component.exports