























import { Component, VModel, Vue } from 'vue-property-decorator'
import { BButton } from 'bootstrap-vue'

@Component({
  components: {
    BButton
  }
})
export default class CheckboxButton extends Vue {
  @VModel({ type: [Boolean, String] }) checked!: boolean|string
}
